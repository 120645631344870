import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import Button from "@bit/azheng.joshua-tree.button"
import ImageMeta from "../components/ImageMeta"

const SecondChance = () => {
  const hasForm = false

  return (
    <SharedStateProvider>
      <Layout>
        <SEO
          lang="en"
          title="Second Chance Program at Northern Nevada Oral & Maxillofacial Surgery: Dental Implants & Wisdom Teeth"
          description="It is time for a brand new, healthy, beautiful smile."
        />

        <div className="sc-program">
          <div className="sc__hero">
            <div className="sc__hero--desktop">
              <img
                className="sc__hero-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/v1659042618/Programs/second-chance-desktop-hero-image.jpg"
                alt="program banner"
              />
            </div>
            <div className="sc__hero--mobile">
              <img
                className="sc__hero-img"
                src="https://res.cloudinary.com/nuvolum/image/upload/v1659042617/Programs/second-chance-mobile-hero-image.jpg"
                alt="program banner"
              />
            </div>
          </div>

          <div className="sc__section">
            <div className="">
              <div className="sc__about-grid--content">
                <p className="sc__subheading">
                  A Second Chance for a Healthy Smile{" "}
                </p>
                <br />
                <p>
                  Northern Nevada Oral & Maxillofacial Surgery is proud to
                  announce our 2022 Second Chance recipient. This life-changing
                  program provides one deserving recipient who has many missing,
                  broken, and/or decaying teeth with a complete smile makeover
                  free of charge.
                </p>
                <p>
                  Kyle, 35, lives in Reno and works as a refinery operator for a
                  bio-energy company. By the age of 12, Kyle had cavities in
                  almost all of his molars, and many of his front teeth were
                  developing cavities at the gum line. He had some of his molars
                  pulled out in his late twenties because of intense pain. Now,
                  his other molars are nearly useless, and his other teeth have
                  cavities, are deteriorating, and breaking.
                </p>
                <p>
                  In his application, Kyle wrote that he used to love smiling,
                  but now he is so self-conscious about what others think and
                  see, it’s difficult for him to even talk. He wants to restore
                  his smile, speak confidently, and be an example for his
                  children, hoping that he can be an inspiration to them to
                  smile and have positive interactions with others. Most of all,
                  he wants them to remember his smile. He hopes to continue on a
                  path to his future as he focuses on hope, integrity, and
                  appreciation.
                </p>
                <br />
                <p className="sc__subheading">Full-Arch Restoration</p>
                <br />
                <p>
                  <Link
                    to="/oral-surgery-procedures/full-arch-restoration-reno-sparks-nv/"
                    title="Learn more about full-arch restoration"
                  >
                    Full-arch restoration
                  </Link>{" "}
                  is a healthy and effective way to replace many missing teeth.
                  The procedure works by securing a full prosthesis in the mouth
                  using as few as four dental implants. The dental implant posts
                  will permanently hold your new teeth in place while also
                  preventing bone loss.
                </p>
              </div>
              {/* <div className="sc__about-grid--info">
                <div className="sc__brackets sc__brackets--desktop">
                  <div>
                    <p className="sc__subheading">How To Apply</p>
                    <ul>
                      <li>Complete the short application.</li>
                      <li>Upload photos of your smile and teeth.</li>
                      <li>Submit your application by September 22, 2022.</li>
                    </ul>
                  </div>
                   <div>
                        <p className="sc__subheading">Treatment Partners:</p>
                        <ul>
                            <li>Restorative Partner: Dr. Rosa Mathai from <a href='https://www.westportaldentalcare.com/' title='Go to West Portal Dental Care Website' target="_blank">West Portal Dental Care</a></li>
                            <li>Dental Lab: </li>
                            <li>Implant Provider: </li>
                        </ul>
                    </div>  
                </div>
              </div> */}
            </div>
          </div>

          {hasForm && (
            <>
              {/* <div className="sc__apply-banner">
                <p>CLICK THE BUTTON BELOW TO FILL OUT THE FORM</p>
              </div> */}

              <div className="sc__section sc__form">
                {/* <iframe
                  className="seamlessdocsEmbededIframe"
                  src="https://secureform.seamlessdocs.com/f/6dd91f5ce33a8fe86c4d9fddb8867129?embedded=true"
                  width="100%"
                  height="700px"
                  frameborder="0"
                  allowtransparency="true"
                  sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
                ></iframe> */}

                {/* <a
                  className="standard-button contained"
                  href="https://secureform.seamlessdocs.com/f/6dd91f5ce33a8fe86c4d9fddb8867129?embedded=true"
                  target="_blank"
                >
                  Submit Application
                </a> */}
                <p>
                  <i>
                    Thank you for your interest in the Second Chance program.
                    The application period is now closed. Applicants will be
                    notified once the selection process has been completed.
                  </i>
                </p>
              </div>
            </>
          )}

          <div className="sc__section">
            <p>
              Follow Kyle’s journey to a new smile on{" "}
              <a
                href="https://www.facebook.com/northernnevadaoms"
                target="_blank"
                title="Follow us on Facebook"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/northernnevadaoms/"
                target="_blank"
                title="Follow us on Instagram"
              >
                Instagram
              </a>
              {"!"}
            </p>
            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/oral-surgery-procedures/dental-implants-reno-nv/"
                  title="Learn more about dental implants"
                >
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/oral-surgery-procedures/full-arch-restoration-reno-sparks-nv/"
                  title="Learn more about full-arch restoration"
                >
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
          <div className="sc__section close-sfs-slider close-sc-slider">
            <ImageMeta
              cloudName="nuvolum"
              publicId="NNOMS/DEV/sc-22-01"
              responsive
              responsiveUseBreakpoints="true"
              noLazyload
            />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SecondChance
